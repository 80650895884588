.upload-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px; /* Add margin bottom to create space between the form and the tiles */
  justify-content: center;
  width: 100%; /* Ensure the form takes the full width */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 10px; /* Add gap between the elements */
}

.upload-form input[type="file"] {
  display: none; /* Hide the file input */
}

.drop-area, input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 200px; /* Ensure a minimum width for text inputs and drop area */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  text-align: center; /* Center the text in the drop area */
}

.drop-area {
  border-style: dashed;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px; /* Make the text inside the drop area smaller */
}

.drop-area.dragging {
  background-color: #e0e0e0;
}

.drop-area p {
  margin: 0;
}

.add-item-btn {
  padding: 10px 20px;
  background: #28a745; /* Green background for the Add button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 0 1 auto; /* Allow the button to shrink and grow */
}

.add-item-btn:hover {
  background: #218838;
}

/* Separator styling */
.separator {
  width: 100%;
  height: 2px;
  background: #ccc;
  margin: 20px 0;
}

/* Responsive design for mobile view */
@media (max-width: 768px) {
  .upload-form {
    flex-direction: column;
    align-items: stretch;
  }

  .drop-area, input[type="text"], .add-item-btn {
    width: 100%;
    margin-bottom: 10px; /* Add margin bottom to create space between elements in column layout */
  }

  .add-item-btn {
    margin-bottom: 0; /* Remove margin bottom for the last element */
  }
}
