.App {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.sidebar-open .main-content {
  margin-left: 290px; /* Increase this value to add more space */
}

.sidebar {
  position: fixed;
  z-index: 1000;
  width: 250px; /* Ensure this matches the width used in margin-left above */
}

.main-content {
  flex: 1;
  overflow-y: auto;
  transition: margin-left 0.3s ease-in-out;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.header h2 {
  display: flex;
  align-items: center;
}

.toggle-btn {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  margin-right: 10px;
}

.dark-mode-toggle {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.dark-mode .header {
  background: #333;
  color: #fff;
}

.dark-mode .main-content {
  background: #222;
  color: #fff;
}

.dark-mode .sidebar {
  background: #444;
}

.dark-mode .category-btn,
.dark-mode .add-category-btn,
.dark-mode .import-export-btn {
  background: #555;
  color: #fff;
}

.dark-mode .delete-btn {
  background: #ff5555;
}

.dark-mode .item-box {
  background: #333;
  color: #fff;
}

.dark-mode .item-box img {
  filter: brightness(0.8);
}

@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .sidebar-open .main-content {
    margin-left: 0;
  }

  .main-content {
    margin-left: 0;
    padding: 20px;
  }

  .item-box {
    flex: 0 0 calc(50% - 10px); /* Two items per row on mobile */
    max-width: calc(50% - 10px);
  }

  .header-buttons {
    display: flex;
    align-items: center;
  }

  .toggle-btn {
    font-size: 1.2em;
  }

  .dark-mode-toggle {
    font-size: 1.2em;
    margin-left: 10px;
  }

  .desktop-only {
    display: none;
  }
}

@media (min-width: 769px) {
  .mobile-only {
    display: none;
  }
}

.error-message {
  color: red;
  margin: 10px 0;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
