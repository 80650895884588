.sidebar {
  width: 250px;
  background: #f4f4f4;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  overflow-y: auto; /* Enable vertical scrollbar */
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-list {
  list-style: none;
  padding: 0;
  max-height: calc(100vh - 150px); /* Adjust based on header and footer height */
  overflow-y: auto; /* Enable vertical scrollbar */
}

.category-list li {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.category-btn {
  flex-grow: 1;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: left;
  position: relative;
}

.category-btn.selected {
  background: #0056b3;
}

.category-btn:hover {
  background: #0056b3;
}

.delete-btn {
  padding: 10px;
  background: #ff0000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
  height: 100%;
}

.add-category-btn,
.import-export-btn {
  width: 100%;
  padding: 10px;
  background: #fd7e14;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: left;
}

.add-category-btn:hover,
.import-export-btn:hover {
  background: #e06c00;
}

.separator-line {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

/* Custom scrollbar styling */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.sidebar::-webkit-scrollbar-track {
  background: #f4f4f4;
}
