.items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.item-box {
  width: 200px; /* Standardize width */
  height: 300px; /* Standardize height */
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: white;
  cursor: pointer;
  z-index: 1;
}

.image-container {
  position: relative;
  width: 100%;
  height: 70%; /* Adjust height for image container */
}

.image-container img {
  width: 100%;
  height: 100%; /* Ensure the image fits the container */
  display: block;
  object-fit: cover; /* Ensure the image covers the container */
}

.item-box p {
  margin: 10px;
  text-align: center;
  min-height: 40px; /* Ensure the description field has a fixed height */
  max-height: 60px; /* Ensure the description field has a maximum height */
  overflow: hidden; /* Hide overflow text */
}

.item-link {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
}

.item-link:hover {
  text-decoration: underline; /* Underline on hover */
}

.crossed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3em;
  text-align: center;
  pointer-events: none;
  display: none;
  z-index: 2; /* Ensure the marker is on top of the picture */
}

.crossed.visible {
  display: flex;
  pointer-events: all;
}

.delete-item-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  z-index: 3;
}
